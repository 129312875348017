<template>
  <div class="k-field-wrapper" :class="this.grid">
    <v-autocomplete :clearable="clearable"
                    :label="translatedLabel"
                    :rules="computedRules"
                    v-bind="fieldProps"
                    v-on="$listeners">
      <!--
      @TODO: This is a workaround - $slots comes after $scopedSlots.
      As $scopedSlots fails when it does not have props while slotProps is included.
      See https://github.com/vuetifyjs/vuetify/issues/13571
      -->
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
        <slot :name="name" v-bind="slotProps"/>
      </template>
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name"/>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  mixins: [Field],
  name: 'KAutocomplete',
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

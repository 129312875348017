<template>
  <div>
    <k-crud-layout :search.sync="searchQuery">
      <template #header>{{ $t('exchangeRate.pageTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile @click="handleOpenCreateDialog">
          {{ $t('exchangeRate.create') }}
        </v-btn>
        <ExchangeRateForm v-model="createDialog"
                     :request="createRequest"
                     :title="$t('exchangeRate.create')"
                     :values="createFormValues"
                     @change="$refs.table.reload()"/>
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      language-prefix="exchangeRate.fields"
                      resource="exchangeRate"
                      @click:edit="openUpdate"
                      @click:row="openUpdate">
        </k-crud-table>
      </template>

    </k-crud-layout>

    <ExchangeRateForm v-model="updateDialog"
                 :request="updateRequest"
                 :title="$t('exchangeRate.update')"
                 :values="updateFormValues"
                 is-update-form
                 @change="$refs.table.reload()"/>
  </div>
</template>

<script lang="js">
import eventBus from '@/application/eventBus.js';
import ExchangeRate from '@/application/models/ExchangeRate.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/exchangeRate/api/exchangeRate.js';
import ExchangeRateForm from '@/modules/exchangeRate/components/ExchangeRateForm.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ExchangeRateResource',
  components: {
    ExchangeRateForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      createDialog: false,
      createFormValues: new ExchangeRate(),
      dialogOpen: false,
      updateDialog: false,
      updateFormValues: new ExchangeRate(),
    };
  },
  computed: {
    ...mapGetters('settings', { currentClientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'currencyName',
          language: 'currencyId',
        },
        {
          value: 'rate',
          language: 'rate',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'exchangeRate.index' },
        text: this.$t('exchangeRate.pageTitle'),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new ExchangeRate();
      this.createDialog = true;
    },
    createRequest() {
      return create(this.currentClientId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async indexRequest(...args) {
      const result = await index(this.currentClientId, ...args);
      result.data.data = result.data.data.map(exchangeRate => new ExchangeRate().mapResponse(exchangeRate));
      return result;
    },
    async openUpdate(item) {
      this.updateFormValues = new ExchangeRate();
      const showRequest = await show(item.id);
      this.updateFormValues.mapResponse(showRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>

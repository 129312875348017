<template>
  <!-- @TODO: Issue with v-menu in v-dialog: https://github.com/vuetifyjs/vuetify/issues/9283 -->
  <v-dialog v-if="delayedValue"
            :value="value"
            fullscreen
            no-click-animation
            persistent
            transition="dialog-bottom-transition"
            @input="$emit('input', $event)">
    <v-card :class="!hasDefaultSlot && panelSlots.length ? 'form-dialog--panels' : 'form-dialog--single-form'"
            color="background">
      <v-card-title>
        <VSpacer/>
        <v-btn color="secondary" tile outlined @click="handleCancel">{{ $t('actions.cancel') }}</v-btn>
        <v-btn v-if="addAnother && !isUpdateForm"
               :loading="isRequestLoading"
               class="ml-3"
               color="secondary"
               outlined
               @click="handleSaveAndAddAnother()">
          {{ $t('actions.saveAnother') }}
        </v-btn>
        <v-btn :loading="isRequestLoading"
               class="ml-3"
               color="secondary"
               depressed
               tile
               @click="handleSaveAndClose()">
          {{ $t('actions.save') }}
        </v-btn>
      </v-card-title>
      <v-card-text class="pr-12">
        <h2 class="text-h1 secondary--text text--darken-4 mb-6">
          {{ title.toUpperCase() }}
        </h2>
        <v-expand-transition>
          <div v-if="hasServerError">
            <v-alert class="v-alert-lines" outlined type="error">
              {{ customValidationErrorHeader ? customValidationErrorHeader : $t('validation.validationError') }}
            </v-alert>
          </div>
        </v-expand-transition>
        <v-tabs v-if="!hasDefaultSlot && panelSlots.length"
                v-model="activeTab"
                color="primary darken-4"
                vertical
                background-color="transparent">
          <v-tab v-for="(slot, index) in panelSlots"
                 :key="index"
                 v-bind="panels[index]"
                 :class="{'error--text' : panelValidation[index] === false}"
                 class="justify-start">
            {{ panels[index].name || 'tab' }}
          </v-tab>
          <v-tab-item v-for="(slot, index) in panelSlots" :key="index" class="pl-6" eager>
            <v-form ref="form" v-model="panelValidation[index]" lazy-validation>
              <v-row>
                <v-col cols="7">
                  <slot :name="slot"></slot>
                </v-col>
                <v-col cols="5">
                  <div class="vertical-line" :style="{borderLeftColor: $vuetify.theme.currentTheme.primary}"></div>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
        <div v-else>
          <v-form ref="form" v-model="panelValidation[0]" lazy-validation>
            <v-row>
              <v-col cols="7">
                <slot></slot>
              </v-col>
              <v-col cols="5">
                <div class="vertical-line" :style="{borderLeftColor: $vuetify.theme.currentTheme.primary}"></div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus from '@/application/eventBus.js';

export default {
  name: 'KFormDialog',
  data() {
    return {
      delayedValue: false,
      panelValidation: [],
      isRequestLoading: false,
      hasServerError: false,
      activeTab: 0,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    panels: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    request: {
      type: Function,
    },
    addAnother: {
      type: Boolean,
      default: false,
    },
    isUpdateForm: {
      default: false,
      type: Boolean,
    },
    customValidationErrorHeader: {
      type: String,
      default: '',
    },
  },
  computed: {
    panelSlots() {
      return Object.keys(this.$slots).filter((slot) => slot.substr(0, 6) === 'panel.');
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasValidationErrors() {
      return this.panelValidation.includes(false);
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (!oldValue && newValue) {
        //Dialog opened
        this.$nextTick(() => {
          this.addTabEventListener();
        });
        this.delayedValue = true;
        return;
      }
      setTimeout(() => this.delayedValue = false, 500);

    },
  },
  methods: {
    addTabEventListener() {
      for (let i = 0; i < this.$refs.form.length; i++) {
        const inputs = this.$refs.form[i].$el.querySelectorAll('input:not([type=hidden])');
        if (!inputs.length) {
          return;
        }
        inputs[inputs.length - 1].addEventListener('keydown', (e) => {
          if (e.keyCode === 9) {
            if (i === this.$refs.form.length - 1) {
              return;
            }
            this.activeTab++;
          }
        });
      }
    },
    handleCancel() {
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('input', false);
      this.reset();
    },
    validate() {
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.validate();
        });
      } else {
        this.$refs.form.validate();
      }
    },
    reset() {
      this.activeTab = 0;
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.reset();
        });
      } else {
        this.$refs.form.reset();
      }
      this.hasServerError = false;
    },
    resetValidation() {
      if (Array.isArray(this.$refs.form)) {
        this.$refs.form.forEach((form) => {
          form.resetValidation();
        });
      } else {
        this.$refs.form.resetValidation();
      }
      this.hasServerError = false;
    },
    async handleSaveAndClose() {
      await this.handleSave(true);
    },
    async handleSaveAndAddAnother() {
      await this.handleSave(false);
    },
    async handleSave(closeOnSuccess) {
      this.validate();
      if (this.hasValidationErrors) return;
      this.isRequestLoading = true;
      try {
        await this.request();
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t(`actions.${this.isUpdateForm ? 'resourceUpdated' : 'resourceCreated'}`, { resource: this.title }),
        });
        closeOnSuccess && this.closeDialog();
        this.$emit('change');
      } catch (error) {
        if (error.response && error.response.status) {
          this.hasServerError = true;
          this.validate();
          return;
        }
        throw error;
      } finally {
        this.isRequestLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-dialog--panels h2 {
  margin-left: 220px
}

.v-alert-lines {
  white-space: pre-line;
}

::v-deep .theme--light.v-tabs-items {
  background-color: transparent;
}

.v-tabs ::v-deep .v-item-group {
  width: 200px;
}

.vertical-line {
  border-left-width: 1px;
  border-left-style: solid;
  height:            100%;
  position:          absolute;
  left:              80%;
  top:               0;
}
</style>

<template>
  <v-sheet class="pa-5" color="white">
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  name: 'FormSheet',
};
</script>

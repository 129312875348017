<template>
  <k-field-group language-prefix="exchangeRate.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-row class="fill-height">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <CurrencyAutocomplete field="currencyId" v-model="values.currencyId" v-if="!isUpdateForm"/>
              <k-text-field field="rate" v-model="values.rate" type="number"/>
              <k-date-field field="startDate" v-model="values.startDate"/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import CurrencyAutocomplete from '@/modules/exchangeRate/components/CurrencyAutocomplete.vue';

export default {
  name: 'ExchangeRateForm',
  components: {
    CurrencyAutocomplete,
    KDateField,
    KTextField,
    FormSheet,
    KFieldGroup,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
  },
};
</script>

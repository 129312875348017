<template>
  <KAutocomplete v-bind="$attrs" v-on="$listeners" :items="currencies" v-model="selected" item-text="text" item-value="value"/>
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { currency } from '@/application/enums/currency.js';

export default {
  name: 'CurrencyAutocomplete',
  components: {
    KAutocomplete,
  },
  data: () => ({ selected: [] }),
  props: {
    value: {
      type: [Array, Number],
    },
  },
  computed: {
    currencies() {
      return Object.keys(currency).map(key => ({
        text: key,
        value: currency[key],
      }));
    },
  },
  created() {
    if (this.value) this.selected = Array.isArray(this.value) ? this.value : [this.value];
  },
};
</script>

import Model from './model';

class Product extends Model {
  id = 0;
  startDate = '';
  rate = 0;
  currencyId = 1
  currencyName = ''; //@TODO the api returns a name but not an id on the show call, perhaps this should change at some time

  mapForRequest() {
    return {
      startDate: this.startDate,
      rate: this.rate,
      currencyId: this.currencyId,
    };
  }
}

export default Product;

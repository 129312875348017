import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (page, perPage, search, sortBy, descending, params) =>
  getPaginated('exchange-rate', page, perPage, search, sortBy, descending, params);

export const show = (id) => get(`exchange-rate/${id}`);

export const create = (clientId, values) => post('exchange-rate', values);

export const update = (values) => put(`exchange-rate/${values.id}`, values);

<script>
import { translateField } from '@/application/util/translation.js';

export default {
  name: 'BaseField',
  inject: {
    fieldGroup: {
      default: undefined,
    },
  },
  props: {
    field: {
      type: String,
    },
    validationField: {
      type: String,
    },
    label: {
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [String, Number],
    },
    grid: {
      type: [Object, String],
    },
  },
  computed: {
    translatedLabel() {
      if (this.required) {
        return `${this.computedLabel}: *`;
      }
      return `${this.computedLabel}:`;
    },
    computedLabel() {
      if (typeof this.label !== 'undefined') {
        return this.label;
      }
      if (this.fieldGroup && this.fieldGroup.languagePrefix) {
        return translateField(this.field, this.fieldGroup.languagePrefix);
      }
      return this.label || this.field;
    },
    computedRules() {
      const rules = [...this.rules, () => this.getServerError()];
      if (this.required) {
        rules.push((v) => (!!v || v === 0) || this.$t('validation.required', { field: this.computedLabel }));
      }
      return rules;
    },
    fieldProps() {
      if (this.fieldGroup && this.fieldGroup.computedFieldProps) {
        return { ...this.fieldGroup.computedFieldProps, ...this.$attrs };
      }
      return { ...this.$attrs };
    },

  },
  methods: {
    getServerError() {
      const error = this.$store.getters['error/find'](this.validationField || this.field);
      if (error) {
        this.removeServerError(this.field);
        return error;
      }

      return true;
    },
    removeServerError(key) {
      this.$store.commit('error/remove', key);
    },
  },
};
</script>
<style>
.k-field-wrapper {
  padding: 0;
  width:   100%;
}
</style>
